.cat-game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  background-color: rgb(205, 216, 179);
  top: 100px;
  height: 550px;
  width: 300px;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.neko-game-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(90% - 30px);
  padding: 10px;
  background-color: white;
  border-radius: 30px;
  margin-top: 15px;
  text-align: center;
}

.neko-image-container {
  margin-top: 30px;
}
.cat-state-image {
  height: 200px;
}

.game-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 20px;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.carrot-icon {
  height: 25px;
}

.game-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  background-color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Gaegu", sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.game-button:hover {
  transform: scale(1.1);
}

.game-button:active {
  transform: scale(0.9);
}

.game-button:disabled {
  transform: none;
}

.alert {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  animation: jiggleAnimation 1s infinite;
}

@media (max-width: 768px) {
  .cat-game-container {
    left: 20px;
    top: 80px;
  }
}

@media (max-width: 480px) {
  .cat-game-container {
    left: 10px;
    top: 60px;
  }
}

@keyframes jiggleAnimation {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1) rotate(3deg);
  }
  40% {
    transform: scale(1.1) rotate(-3deg);
  }
  60% {
    transform: scale(1.1) rotate(3deg);
  }
  80% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1);
  }
}
