.secret-modal-container {
  position: fixed;
  overflow-y: scroll;
  display: flex;
  height: auto;
  grid-gap: 20px;
  flex-direction: column;
  top: 130px;
  left: 30px;
  bottom: 30px;
  right: 30px;
  padding-bottom: 20px;
  background-color: rgb(229, 242, 197);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  z-index: 3;
}

.back-button-container {
  padding: 20px 20px 0px 20px;
  width: calc(100%-40px);
  display: flex;
  align-items: center;
  justify-content: end;
}

.back-button {
  background-color: white;
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
  font-weight: 600;
  cursor: pointer;
}

.back-button:hover {
  transform: scale(1.1);
}

.back-button::active {
  transform: scale(0.9);
}
.alert-text {
  margin: auto;
  font-size: 22px;
  background-color: white;
  border-radius: 30px;
  padding: 0 10px 0 10px;
  max-width: 80%;
}

.desktop-view-container {
  height: auto;
  width: 90%;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  flex-direction: column;
  background-color: white;
  border-radius: 30px;
  margin: auto;
  padding-bottom: 20px;
}

.desktop-header {
  font-size: 20px;
  height: 30px;
  display: flex;
  padding: 0 20px 0 20px;
  width: calc(100% - 40px);
  font-weight: 600;
  align-items: center;
  border-bottom: 7px dashed rgb(205, 216, 179);
}

.secret-website-desktop {
  width: 80%;
  max-width: 900px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.secret-website-mobile {
  max-height: 500px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.mobile-view-container {
  height: auto;
  width: 90%;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  flex-direction: column;
  background-color: white;
  border-radius: 30px;
  padding-bottom: 20px;
  margin: auto;
}

.mobile-header {
  font-size: 20px;
  height: 30px;
  display: flex;
  padding: 0 20px 0 20px;
  width: calc(100% - 40px);
  font-weight: 600;
  align-items: center;
  border-bottom: 7px dashed rgb(205, 216, 179);
}

@media (max-width: 480px) {
  .alert-text {
    font-size: 18px;
    padding: 10px 20px 10px 20px;
  }
}
