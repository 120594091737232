.nav-container {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  justify-content: space-between;
  background-color: rgb(229, 242, 197);
  z-index: 2;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.left {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  grid-gap: 40px;
  align-items: center;
}

.right {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  grid-gap: 40px;
  align-items: center;
}
.nav-icon {
  height: 70px;
  border-radius: 30px;
}

.nav-icon:active {
  transform: scale(0.9);
}

.nav-link {
  cursor: pointer;
}

.currency-icon {
  height: 50px;
  position: absolute;
  border-radius: 50px;

  top: 5px;
  left: 10px;
}

.currency-container {
  height: 60px;
  width: 200px;
  background-color: white;
  border-radius: 30px;
  position: relative;
  justify-content: center;
  align-items: center;
  color: black;
}

.currency-text {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.point-tracker {
  z-index: 10;
  position: fixed;
  top: 120px;
  right: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  background-color: white;
  border-radius: 30px;
  border: 2px solid rgb(205, 216, 179);
  padding: 0 10px 0 10px;
  font-size: 30px;
  animation: jiggleAnimation 1s infinite;
}

.point-tracker-carrot {
  height: 25px;
}

.quest-btn {
  display: inline-block;
  animation: jiggleAnimation 2s infinite;
}

@media (max-width: 768px) {
  .nav-container {
    height: 80px;
  }

  .left,
  .right {
    margin-left: 10px;
    margin-right: 10px;
    grid-gap: 20px;
  }

  .nav-icon {
    height: 50px;
  }

  .currency-container {
    height: 50px;
    width: 150px;
  }

  .currency-icon {
    height: 35px;
    top: 8px;
  }

  .point-tracker {
    top: 100px;
    right: 20px;
    font-size: 18px;
  }

  .point-tracker-carrot {
    height: 20px;
  }
}

/* Mobile styles */
@media (max-width: 480px) {
  .nav-container {
    height: 60px;
  }

  .left,
  .right {
    margin-left: 5px;
    margin-right: 5px;
    grid-gap: 10px;
  }

  .nav-icon {
    height: 40px;
  }

  .currency-container {
    height: 40px;
    width: 120px;
  }
  .currency-icon {
    height: 30px;
    top: 5px;
  }

  .currency-text {
    font-size: 14px;
  }

  .point-tracker {
    top: 90px;
    right: 10px;
    font-size: 16px;
  }

  .point-tracker-carrot {
    height: 12px;
  }
}

@keyframes jiggleAnimation {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2) rotate(3deg);
  }
  40% {
    transform: scale(1.2) rotate(-3deg);
  }
  60% {
    transform: scale(1.2) rotate(3deg);
  }
  80% {
    transform: scale(1.2) rotate(-3deg);
  }
  100% {
    transform: scale(1);
  }
}
