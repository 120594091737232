.landing-wrapper {
  margin-top: 100px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
}

.personal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  height: 520px;
}

.pro-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  width: 90%;
  grid-gap: 30px;
  height: 520px;
}

.landing-container {
  width: 100%;
  height: 520px;
  display: flex;
  flex-direction: row;
}

.text-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  align-items: center;
  font-size: 18px;
  line-height: 1.7;
  text-align: center;
}

.iamprogrammer-image {
  height: 200px;
}
.about-text {
  width: 70%;
}
.name-text {
  font-size: 28px;
  font-weight: 600;
}

.role-text {
  font-size: 22px;
  font-weight: 600;
  background-color: rgb(229, 242, 197);
  border-radius: 10px;
  padding: 5px;
}

.portrait-image-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  align-items: center;
  font-size: 20px;
  text-align: center;
}

.sarah-rose-image {
  /* border-radius: 50px; */
  max-height: 400px;
  border-radius: 50%;
}

.carosel-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  grid-gap: 10px;
}

.prev-button,
.next-button {
  cursor: pointer;
  background: none;
  border: none;
  font-family: "Gaegu", sans-serif;
  font-size: 20px;
}

.prev-button:hover,
.next-button:hover {
  transform: scale(1.1);
}

.prev-button:active,
.next-button:active {
  transform: scale(0.9);
}
.prev-button:disabled,
.next-button:disabled {
  transform: none;
}

.elipse-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
}

.period {
  font-size: 15px;
  font-weight: 600;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highlight-one {
  background-color: rgb(251, 197, 255);
  border-radius: 10px;
  padding: 3px;
}

.highlight-two {
  background-color: rgb(255, 247, 176);
  border-radius: 10px;
  padding: 3px;
}

.highlight-three {
  background-color: rgb(217, 244, 255);
  border-radius: 10px;
  padding: 3px;
}

.highlight-four {
  background-color: rgb(255, 189, 167);
  border-radius: 10px;
  padding: 3px;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slide-in-right {
  animation: slideInFromRight 0.5s forwards;
}

.slide-out-left {
  animation: slideOutToLeft 0.5s forwards;
}

.slide-in-left {
  animation: slideInFromLeft 0.5s forwards;
}

.slide-out-right {
  animation: slideOutToRight 0.5s forwards;
}

@media (max-width: 900px) {
  .landing-wrapper {
    height: auto;
    margin-top: 30px;
  }

  .landing-container {
    margin-top: 50px;
    flex-direction: column;
    padding: 10px;
  }

  .iamprogrammer-image {
    height: 150px;
  }

  .text-container {
    width: 100%;
    padding: 10px;
  }

  .about-text {
    width: 90%;
    font-size: 18px;
    line-height: 1.5;
  }

  .name-text {
    font-size: 24px;
  }

  .role-text {
    font-size: 20px;
  }

  .portrait-image-container {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .sarah-rose-image {
    max-height: 150px;
  }
}

@media (max-width: 480px) {
  .landing-wrapper {
    height: 600px;
  }

  .about-text {
    width: 90%;
    font-size: 16px;
    line-height: 1.5;
  }

  .landing-container {
    flex-direction: column;
    padding: 0px;
  }

  .iamprogrammer-image {
    height: 100px;
  }

  .text-container {
    width: calc(100% - 20px);
    grid-gap: 10px;
  }

  .name-text {
    font-size: 20px;
  }

  .role-text {
    font-size: 18px;
  }

  .portrait-image-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .sarah-rose-image {
    max-height: 100px;
  }

  .carosel-buttons {
    height: 60px;
    grid-gap: 5px;
  }

  .prev-button,
  .next-button {
    font-size: 18px;
  }

  .elipse-container {
    grid-gap: 10px;
  }

  .period {
    font-size: 12px;
  }
}
